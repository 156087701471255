.navbar-brand{
  font-family: 'Lato', sans-serif !important;
  color: #444 !important;
  font-size: 28px !important;
  font-weight: 900;
  text-transform: uppercase;
}
*:focus {
  box-shadow: none !important;
}
.App-header {
  transition: all 0.5s;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 22%);
}
.theme-color{
  color: #e8505b !important;
}
.container{
  overflow: hidden;
}
p{
  color: #444;
}
.theme-color-2{
  color: #1c698c !important;
}

.theme-color.nav-link{
  color: #444 !important;
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
}
.get-started a.nav-link{
  background: #e8505b;
  color: #fff !important;
  padding: 15px 30px !important;
  border-radius: 50px;
}
.get-started a.nav-link:hover{
  color: #fff !important;
}
body .bg-body-tertiary{
  --bs-bg-opacity: 1;
  background-color: rgba(255,255,255, 1) !important;
}
.bg-scroll  .theme-color.nav-link{
  color: #000 !important;
}
.bg-scroll .theme-color.nav-link.item--active {
  color: #e8505b !important;
  /* background: #fff !important; */
}
.offcanvas.offcanvas-end.show .theme-color.nav-link.item--active{
  color: #e8505b !important;
}
.theme-color.nav-link:hover {
  color: #e8505b !important;
  /* background: #fff !important; */
}
.heroSection{
  padding: 200px 0 50px;
}
.hero{
  background-image: url('../public/assets/img/hero-bg.png');
  background-position: top center;
  background-size: 100%;
  background-repeat: no-repeat;
}
.heroSection h1{
  margin: 0;
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  text-transform: uppercase;
}
.heroSection .typetext{
  font-size: 20px;
}
.heroSection .btn-cs {
  font-weight: 500;
  font-size: 16px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 15px 28px;
  border-radius: 50px;
  transition: 0.5s;
  margin-top: 25px;
  color: #fff;
  background: #e8505b !important;
  text-transform: uppercase;
  border: 0 !important;
}
.heroSection .btn-cs:hover {
  background: #76c7ed;
}



.about{
  background: url('../public/assets/img/left.png') no-repeat;
  background-size: 5%;
  background-position: left bottom;
}

.about h2 {
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 15px;
}
.site-name{
  font-weight: bold;
}
.about p{
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
}

#home{
  scroll-margin-top: 0;
}
#about,
#partners,
#advertising,
#why-choose,
#publisher,
#contact{
  scroll-margin-top: 60px;
}

.icon-cs {
  position: relative;
  font-size: 30px;
  color: #e8505b !important;
}
/* .icon-section{
  border: 1px solid #49b5e7;
  text-align: center;
  padding: 9px;
  border-radius: 40px;
  width: 60px;
  height: 60px;
  margin: auto;
  line-height: 35px;
} */
.item-list-cs{
  margin: 50px 0 !important;
  padding: 0 !important;
}
.item-list-cs h3{
  font-weight: 700;
  margin-bottom: 10px;
  font-size: 18px;
}
.item-list-cs p{
  font-size: 14px;
}
.item-list-cs li{
  margin-bottom: 20px;
}

/* .item-list-cs li:hover .icon-section{
  transition: all 0.3s ease-in-out ;
  background: #49b5e7;
}
.item-list-cs li:hover .icon-section .icon-cs {
  color: #fff;
  transition: all 0.3s ease-in-out ;
} */
.item-list-cs li:hover h3{
  color: #e8505b;
  transition: all 0.3s ease-in-out ;
}
.item-list-cs .btn-cs{
  background: #e8505b !important;
  border: 0 !important;
  color: #fff;
}
.counts .title h3 {
  font-size: 36px;
  font-weight: 700;
}
.about-company .col{
  position: relative;
  z-index: 1;
}
.about-company::before {
  content: "";
  position: absolute;
  background: rgba(255, 255, 255, 0.8);
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.about-dmm{
  padding: 80px 0 ;
}
.about-company p{
  font-size: 16px;
}
.about-company > div{
  position: relative;
  z-index: 1;
}
.animated-number p.number{
  font-size: 44px;
  font-weight: 700;
  display: block;
  color: #49b5e7;
  font-family: "Dosis", sans-serif;
  margin-bottom: 0;
}
.animated-number p.desc{
  padding: 0;
  margin: 0 0 20px 0;
  font-size: 15px;
  color: #444444;
}


/* partners */

#partners h2{
  font-size: 1.75rem;
  color: #444;
}
img.brand-img {
  width: 50%;
  max-width: 100%;
  transition: all 0.3s ease-in-out ;
}
img.brand-img:hover {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out ;
}
.brand-section{
  margin-bottom: 40px;
  padding: 20px 0;
  border: 1px solid #eee;
}

.brand-section p{
  margin-bottom: 0   !important;
}
.partners{
  background-color: #f4fbfe;
}
.partners-section{
  padding: 100px 0;
}
.partners-section h2{
  font-size: 32px;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 20px;
    padding-bottom: 0;
    color: #0f394c; 
}
.partners-section img.card-img-top {
  width: 100%;
  margin: auto;
}
.card-img-extra-paddng {
  padding: 20px;
  border: 0 !important;
}
.card{
  border-radius: 0 !important;
}
.card-img-extra-paddng:hover {
  transform: scale(1.2);
  transition: all 0.3s ease-in-out ;
}


/* advertising */

.advertising{
  padding: 100px 0;
}
.advertising h2{
  text-transform: uppercase;
}

/* why-choose */
.why-choose{
  padding: 100px 0;
  background-color: #f4fbfe;
  background-image: url('../public/assets/img/left.png');
  background-repeat: no-repeat;
  background-size: 10%;
  background-position: left bottom;
}
.why-choose .item-list-cs{
  margin: 0 !important;
}
.why-choose .item-list-cs p{
  font-size: 18px;
}
.why-choose .icon-section{
  text-align: center;
}


/* publisher */

.publisher{
  padding: 100px 0;
}
.publisher h2{
  text-transform: uppercase;
}


/* contact */

#contact{
  padding: 80px 0;
  background: #f4fbfe;
}
#contact h2{
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 0;
}

#contact .icon-section {
  border: 1px solid #e8505b !important;
  text-align: center;
  padding: 9px 12px;
  border-radius: 40px;
  width: 50px;
  height: 50px;
  margin: auto;
  line-height: 25px;
}
#contact a{
  color: #e8505b !important;
}
#contact .icon-cs {
  position: relative;
  font-size: 25px;
  color: #49b5e7;
}
#contact li:hover .icon-cs {
  color: #fff;
}

#contact a:hover{
  text-decoration: underline;
}

#contact form input,
#contact form textarea{
  padding: 10px;
  margin: 10px;
}
#contact form{
  font-family: 'Open Sans', sans-serif;
}
#contact .cs-btn-form{
  background: #e8505b;
  border: 0;
  padding: 10px 24px;
  color: #fff;
  transition: 0.4s;
  border-radius: 4px;
  font-family: 'Open Sans', sans-serif;
}
#contact .cs-btn-form:hover {
  background: #e8505b;
}

/* footer */
footer .bg-news{
  background: #f0f9fd;
}
footer .news-letter{
  padding: 50px 0;
  text-align: center;
  font-size: 15px;
}
footer .news-letter h2 {
  font-size: 24px;
  margin: 0 0 20px 0;
  padding: 0;
  line-height: 1;
  font-weight: 600;
  color: #0f394c;
}

footer form {
  margin-top: 30px;
  background: #fff;
  position: relative;
  border-radius: 4px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  text-align: left;
}
footer form input[type="email"] {
  border: 0;
  padding: 15px;
  width: calc(100% - 100px);
  outline: none;
}
footer form input[type="email"]:focus {
  outline: 0 !important;
  box-shadow: inset 0 -1px 0 #ddd;
}
form.form-static {
  max-width: 600px;
  margin: auto;
}

footer form .cs-btn-form {
  font-size: 16px;
  padding: 10px 20px;
  background: #e8505b;
  color: #fff;
  transition: 0.3s;
  border-radius: 0 4px 4px 0;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  border: 0;
}
footer form .cs-btn-form:hover {
  background: #e8505b;
}
.form-holder{
  display: flex;
  margin: 0;
  padding: 0 !important;
}

.footer-link{
  padding: 60px 0;
}

.footer-link p,
.footer-link a{
  color: #777 !important;
  font-family: "Open Sans", sans-serif;
}

span.sm-icons {
  display: inline-flex;
  padding: 10px;
  background: #e8505b;
  margin: 0 5px;
  border-radius: 5px;
}

span.sm-icons svg{
  color: #fff;
  font-size: 18px;
}

footer h6 {
  font-size: 16px;
  font-weight: bold;
  position: relative;
  padding-bottom: 12px;
}
footer .info{
  padding: 0;
  color: #777;
  font-size: 14px;
  font-family: "Lato", sans-serif;
}
footer .link{
  padding: 0;
}
footer .link li a{
  color: #777;
  text-decoration: none;
}
footer .link li a:hover{
  color: #e8505b !important;
}
footer .link li{
  margin: 10px 0;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
}
footer .link li svg{
  color: #e8505b !important;
}
footer .info a{
  color: #e8505b !important;
  text-decoration: none;
}
footer .list-group-item{
  padding: 0;
    border: 0;
    margin: 0;
}
.copyright{
  background: #ebf7fc !important;
}
.copyright p{
  margin-bottom: 0;
  padding: 20px 0;
  font-size: 14px;
}
.scroll-to-top{
  background: #e8505b !important;
}

.bg-scroll .get-started a.nav-link,
.bg-scroll .get-started a.theme-color.nav-link.item--active{
  color: #fff  !important;
}
.offcanvas[role="dialog"] .get-started{
  display: none !important;
}
/* terms */

.terms-condition{
  margin-top: 70px;
}
.heading-section{
  padding: 40px 0 40px;
  background: #f0f9fd;
}
.heading-section h2{
  margin: 0 !important;
}
.breadcrumb{
  font-family: "Open Sans", sans-serif;
  margin-bottom: 0 !important;
}
.breadcrumb a{
  color: #e8505b !important;
  margin-right: 5px;
  text-decoration: none;
}
.terms-desc{
  padding: 40px 0;
  font-family: "Open Sans", sans-serif;
}
.terms-desc h2{
  margin: 30px 0 10px;
}
.terms-desc .list-group-item{
  border: 0;
  padding: 10px 0;
}
.terms-desc a {
  color: #76c7ed;
  text-decoration: none;
}
.offcanvas-title{
  text-transform: uppercase;
}

@media only screen and (max-width: 1023px) {
  .advertising {
    padding: 40px 0;
}
  .about-dmm h2 {
    margin-top: 20px;
  }
  #partners {
    padding: 40px 0 0;
  }
  .partners-section {
    padding: 40px 0;
  }
  .heroSection {
    padding: 100px 0 40px;
  }
  #contact {
    padding: 40px 0;
  }
  .about-dmm {
    padding: 40px 0;
  }
  .about-company {
    padding: 40px 0;
  }
  .item-list-cs {
    margin: 40px 0 0 !important;
  }
  .why-choose{
    padding: 40px 0 !important;
  }
  .publisher {
    padding: 40px 0;
}
}
@media only screen and (max-width: 991px) {
  .heroSection .row{
    flex-direction: column-reverse;
  }
  [data-aos^=fade][data-aos^=fade].aos-animate,
  [data-aos^=zoom][data-aos^=zoom].aos-animate{
    opacity: 1 !important;
    transition: unset !important;
    transition-delay: 0!important;
  }
  [data-aos^=fade],
  [data-aos^=zoom] {
    transform: unset !important;
    transition-delay: 0!important;
  }
}
@media only screen and (max-width: 767px) {

  .heroSection .typetext{
    font-size: 16px;
  }
  #partners {
    padding: 20px 0 0;
  }
  .partners-section {
    padding: 20px 0;
  }
  #contact {
    padding: 20px 0;
  }
  .about-dmm {
    padding: 20px 0;
  }
  .about-company {
    padding: 20px 0;
  }
  .item-list-cs {
    margin: 20px 0 0 !important;
  }
  footer .news-letter {
    padding: 20px 0;
  }
  .footer-link {
    padding: 20px 0;
  }

  #partners h2 ,
  .about h2,
  #contact h2,
  footer .news-letter h2{
    font-size: 1.2rem;
  }
  .heroSection h1 {
    font-size: 1.4rem;
    line-height: 35px;
  }
  .heroSection .btn-cs{
    margin-top: 10px;
  }
  .about-dmm h2{
    margin-top: 20px;
  }
  .icon-section{
    margin-bottom: 10px;
  }
  .navbar-brand{
    font-size: 20px !important;
  }
  .heroSection {
    padding: 70px 0 20px;
  }
  .copyright p{
    text-align: center;
  }
  .footer-link.theme-color-2.list-group-item{
    text-align: center;
  }
  .form-control{
    width: 98%;
  }
  #contact form input, #contact form textarea {
    margin: 1%;
  }
  .bg-news form.form-static {
    width: 90%;
  }
  .item-list-cs{
    text-align: center;
  }
  .footer-link .list-group{
    text-align: center;
  }
  .terms-condition {
    margin-top: 57px;
  }
  .heading-section {
    padding: 20px 0;
    text-align: left;
  }
  .heading-section .row > div > p{
    float: unset !important;
  }
  .terms-desc {
    padding: 20px 0;
  }

}